import api from './api';

class ProcesoCalidadService {

  getItems(cadenaBusqueda) {
    cadenaBusqueda = cadenaBusqueda ? cadenaBusqueda : ''
    return api.get(`/api/procesos_calidad${cadenaBusqueda}`);
  }

  getItem(id) {
    return api.get(`/api/procesos_calidad/${id}`);
  }

  newItem(layout_id, params) {
    return api.post(`/api/layouts/${layout_id}/procesos_calidad/`, params)
  }

  editItem(layout_id, params) {
    return api.put(`/api/layouts/${layout_id}/procesos_calidad/${params.id}`, params)
  }

  deleteItem(layout_id, procesoId) {
    return api.delete(`/api/layouts/${layout_id}/procesos_calidad/${procesoId}`)
  }
  
}

export default new ProcesoCalidadService();
