<template>
  <div>
    <v-card elevation="2" tile>
      <v-card-title
        >Edición del Proceso Calidad {{ proceso.nombre }}</v-card-title
      >
      <v-divider></v-divider>
      <v-form v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="proceso.nombre"
                label="Nombre del Proceso"
                :rules="
                  required.concat(
                    (v) => (v && v.length <= 100) || 'Máximo 100 caracteres'
                  )
                "
                :counter="100"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <div
                v-if="loadingLayouts"
                class="spinner-border"
                role="status"
              ></div>
              <v-select
                v-if="!loadingLayouts"
                v-model="proceso.layout_id"
                :items="fuentes"
                :item-text="'nombre'"
                :item-value="'id'"
                attach
                label="Fuente"
                :rules="required"
                :disabled="proceso.hasOwnProperty('id')"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <div
                v-if="loadingTiposOperacion"
                class="spinner-border"
                role="status"
              ></div>
              <v-select
                v-if="!loadingTiposOperacion"
                v-model="proceso.tipo_operacion_id"
                :items="tiposOperacion"
                :item-text="'valor_texto'"
                :item-value="'id'"
                attach
                label="Tipo de Operación"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <div
                v-if="loadingPeriodicidades"
                class="spinner-border"
                role="status"
              ></div>
              <v-select
                v-if="!loadingPeriodicidades"
                v-model="proceso.periodicidad_ejecucion_id"
                :items="periodicidades"
                :item-text="'valor_texto'"
                :item-value="'id'"
                attach
                label="Periodicidad de Ejecución"
                :rules="required"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <div
                v-if="loadingRoles"
                class="spinner-border"
                role="status"
              ></div>
              <v-select
                v-if="!loadingRoles"
                v-model="proceso.rol_ejecutor_id"
                :items="roles"
                :item-text="'name'"
                :item-value="'id'"
                attach
                label="Rol de Ejecutor"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <div
                v-if="loadingRoles"
                class="spinner-border"
                role="status"
              ></div>
              <v-select
                v-if="!loadingRoles"
                v-model="proceso.rol_consulta_id"
                :items="roles"
                :item-text="'name'"
                :item-value="'id'"
                attach
                label="Rol de Consulta"
                :rules="required"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="proceso.descripcion"
                label="Descripción"
                :counter="250"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-switch v-model="proceso.activo" :label="`Estado`"></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-divider></v-divider>
      <!-- <pre>{{proceso}}</pre> -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <CButton color="secondary" @click="preCancelar">Cancelar</CButton>&nbsp;
        <div v-if="loadingSave" class="spinner-border" role="status"></div>
        <CButton
          v-if="!loadingSave"
          color="primary"
          class="px-4"
          @click="save()"
          :disabled="!valid"
          >Guardar</CButton
        >
      </v-card-actions>
    </v-card>

    <v-dialog v-model="dialog" max-width="420">
      <v-card>
        <v-card-title class="text-h5">
          ¿Desea cancelar la edición?
        </v-card-title>

        <v-card-text>
          Si cancela la edición los datos diligenciados no quedarán guardados.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="goBack">
            Cancelar y volver
          </v-btn>

          <v-btn color="green darken-1" text @click="dialog = false">
            Continuar la edición
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ProcesoCalidadService from "@/services/procesoCalidad.service";
import validationField from "@/util/validationField";
import LayoutService from "@/services/layout.service";
import ListaService from "@/services/lista.service";
import RoleService from "@/services/role.service"
import { mapState } from "vuex";

export default {
  name: "Calidad",

  data() {
    return {
      proceso: {
        nombre: undefined,
        layout_id: undefined,
        tipo_operacion_id: undefined,
        periodicidad_ejecucion_id: undefined,
        rol_ejecutor_id: undefined,
        rol_consulta_id: undefined,
        descripcion: undefined,
        activo: true,
      },
      valid: false,
      rules: [
        (value) => !!value || "Requerido",
        (value) => (value && value.length >= 3) || "Mínimo 3 caracteres",
      ],
      required: [(value) => !!value || "Requerido"],
      tiposOperacion: [],
      periodicidades: [],
      roles: [],
      fuentes: [],
      loading: false,
      loadingLayouts: false,
      loadingTiposOperacion: false,
      loadingPeriodicidades: false,
      loadingRoles: false,
      loadingSave: false,
      message: "",
      dialog: false,
    };
  },
  computed: {
    ...mapState(["permissions"]),
  },
  watch: {
   
  },
  mounted() {
    if (!this.permissions.includes("Definición de proceso de calidad"))
      this.$router.push("/dashboard");

    if (localStorage.getItem("datos_proceso_calidad")) {
      this.proceso = JSON.parse(localStorage.getItem("datos_proceso_calidad"));
    }

    this.fetchLayouts("");
    this.fetchTiposOperacion("");
    this.fetchPeriodicidades("");
    this.fetchRoles("");
  },
  methods: {
    save() {
      this.loadingSave = true;
      if (this.proceso.hasOwnProperty("id")) {
        ProcesoCalidadService.editItem(this.proceso.layout_id, this.proceso).then(
          (response) => {
            this.$router.push({ path: `/calidad/procesos-calidad` });
            console.log(response.data);
          },
          (error) => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      } else {
        ProcesoCalidadService.newItem(this.proceso.layout_id, this.proceso).then(
          (response) => {
            this.$router.push({ path: `/calidad/procesos-calidad` });
            console.log(response.data);
          },
          (error) => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
    fetchLayouts(cadenaBusqueda) {
      this.loadingLayouts = true;
      if(cadenaBusqueda=="")
        cadenaBusqueda="?activo=1";
      else
        cadenaBusqueda=cadenaBusqueda+"&activo=1";
      LayoutService.getItems(cadenaBusqueda).then(
        (response) => {
          this.fuentes = response.data;
          this.loadingLayouts = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingLayouts = false;
        }
      );
    },
    fetchTiposOperacion(cadenaBusqueda) {
      this.loadingTiposOperacion = true;
      ListaService.getTiposOperacion().then(
        (response) => {
          this.tiposOperacion = response.data.data;
          this.loadingTiposOperacion = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingTiposOperacion = false;
        }
      );
    },
    fetchPeriodicidades(cadenaBusqueda) {
      this.loadingPeriodicidades = true;
      ListaService.getPeriodicidades().then(
        (response) => {
          this.periodicidades = response.data.data;
          this.loadingPeriodicidades = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingPeriodicidades = false;
        }
      );
    },
    fetchRoles(cadenaBusqueda) {
      this.loadingRoles = true;
      RoleService.getItems(cadenaBusqueda).then(
        (response) => {
          this.roles = response.data;
          this.loadingRoles = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingCampos = false;
        }
      );
    },
    preCancelar() {
      this.dialog = true;
    },
    goBack() {
      this.$router.push({ path: "/calidad/procesos-calidad" });
    },
  },
};
</script>

<style>
</style>
